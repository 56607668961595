<template>
    <div class="w-full flex flex-col bg-gray-50 h-full relative">

        <autralis-success-comp :show="savedFlag"/>
        <autralis-error-comp :show="error" :text="error"/>

        <autralis-modal-comp :modal="modal"/>
        <autralis-loading-table-comp v-if="fetchingAddresses.loading" :columns="columns"/>
        <!--HEADER-->
        <div v-else class="flex flex-col w-full">
            <!-- HEADER -->
            <div class="addresses-header flex justify-between p-6 sticky z-10" :style="'top:' + headerTop + 'px'">
                <div class="flex flex-1 w-full ">
                    <!-- SMALL SCREENS (<768px) -->
                    <div class="flex flex-col xl:hidden w-full">
                        <div class="flex justify-between w-full">
                            <!-- BUTTON ADD NEW -->
                            <div :class="[(showSetDefaultButton || selected.length > 0 || showDeleteButton) ? 'border-r border-gray-200 mr-4 pr-4' : '']">
                                <button @click="addNew"
                                        class="primary-button-dark flex items-center justify-center"
                                        style="height: 38px">
                                    <i class="fal fa-plus mr-1"/><span class="">Add</span>
                                </button>
                            </div>

                            <!-- OTHER BUTTONS DROPDOWN -->
                            <div class="flex" v-if="showSetDefaultButton || selected.length > 0 || showDeleteButton">
                                <!-- SMALL SCREENS (<768) -->
                                <div class="flex items-center justify-center relative">
                                    <i class="fal fa-ellipsis-h-alt text-2xl text-gray-500 hover:text-blue-700 transition ease-in-out duration-150 cursor-pointer"
                                       @click="menuOpen = !menuOpen"/>
                                    <div v-if="menuOpen" class="bg-white absolute right-0 z-10 rounded-md shadow-lg py-4 dropdown-nav space-y-2" style="">
                                        <div v-if="showSetDefaultButton" class="flex-shrink-0">
                                            <button type="button"
                                                    @click="handleDefault"
                                                    style="height: 38px"
                                                    class="secondary-button-light">
                                            <span v-if="defaultAddress.saving" class="flex items-center">
                                                <span class="fas fa-spinner-third fa-spin mr-2"></span> <translate>Saving</translate> ...
                                            </span>
                                                <span v-else><i class="fal fa-home-lg-alt mr-1"/> Make default</span>
                                            </button>
                                        </div>

                                        <div v-if="selected.length > 0" class="flex-shrink-0">
                                            <button type="button"
                                                    @click="handleCompound"
                                                    style="height: 38px"
                                                    class="secondary-button-light">
                                            <span v-if="compoundAddress.saving" class="flex items-center">
                                                <span class="fas fa-spinner-third fa-spin mr-2"></span> <translate>Saving</translate> ...
                                            </span>
                                                <span v-else><i class="fal fa-link mr-1"/>Toggle compound</span>
                                            </button>
                                        </div>

                                        <div v-if="showDeleteButton" class="flex-shrink-0">
                                            <button @click="confirmDelete"
                                                    style="height: 38px"
                                                    class="default-button text-red-500 font-medium sm:text-sm">
                                            <span v-if="deleting" class="flex items-center">
                                                <span class="fas fa-spinner-third fa-spin mr-2"></span> <translate>Deleting</translate> ...
                                            </span>
                                                <span v-else><i class="fal fa-trash mt-1 mr-1"/> Delete</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- FILTERS -->
                        <div class="flex mt-4">
                            <autralis-combo-button-comp :title="sort === null ? 'Sort' : sort.title" :right="false" :items="selectColumns()" @select-item="selectSort" :z="10"/>
                            <div @click="setSortType"
                                 class="inline-flex items-center ml-3 px-2 py-1 border border-gray-300 text-sm leading-5 cursor-pointer font-medium rounded shadow-sm px-4 py-2 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none">
                                <div v-if="sort && sort.type === 'desc'"><i class="fas fa-sort-amount-down"/></div>
                                <div v-else><i class="fas fa-sort-amount-up"/></div>
                            </div>
                        </div>
                    </div>

                    <!-- BIG SCREENS (>=768px) -->

                    <div class="hidden xl:flex justify-between w-full">
                        <div class="flex flex-1">
                            <div :class="[(showSetDefaultButton || selected.length > 0 || showDeleteButton) ? 'border-r border-gray-200 mr-4 pr-4' : '']">
                                <button @click="addNew"
                                        class="primary-button-dark flex items-center justify-center"
                                        style="height: 38px">
                                    <i class="fal fa-plus"/>
                                    <span class="hidden ml-0 md:ml-1 md:block">Add</span>
                                </button>
                            </div>

                            <div class="flex" v-if="showSetDefaultButton || selected.length > 0 || showDeleteButton">
                                <div class="flex">
                                    <div v-if="showSetDefaultButton" class="flex-shrink-0 sm:mr-2">
                                        <button type="button"
                                                @click="handleDefault"
                                                style="height: 38px"
                                                class="secondary-button-light">
                                            <span v-if="defaultAddress.saving" class="flex items-center">
                                                <span class="fas fa-spinner-third fa-spin mr-2"></span> <translate>Saving</translate> ...
                                            </span>
                                            <span v-else><i class="fal fa-home-lg-alt mr-1"/> Make default</span>
                                        </button>
                                    </div>

                                    <div v-if="selected.length > 0" class="flex-shrink-0 border-r border-gray-200 sm:mr-4 sm:pr-4">
                                        <button type="button"
                                                @click="handleCompound"
                                                style="height: 38px"
                                                class="secondary-button-light">
                                <span v-if="compoundAddress.saving" class="flex items-center">
                                    <span class="fas fa-spinner-third fa-spin mr-2"></span> <translate>Saving</translate> ...
                                </span>
                                            <span v-else><i class="fal fa-link mr-1"/>Toggle compound</span>
                                        </button>
                                    </div>

                                    <div v-if="showDeleteButton" class="flex-shrink-0">
                                        <button @click="confirmDelete"
                                                style="height: 38px"
                                                class="error-button font-medium sm:text-sm">
                                <span v-if="deleting" class="flex items-center">
                                    <span class="fas fa-spinner-third fa-spin mr-2"></span> <translate>Deleting</translate> ...
                                </span>
                                            <span v-else><i class="fal fa-trash mt-1 mr-1"/> Delete</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex ml-4">
                            <autralis-combo-button-comp :title="sort === null ? 'Sort' : sort.title" :right="true" :items="selectColumns()" @select-item="selectSort" :z="10"/>
                            <div @click="setSortType"
                                 class="inline-flex items-center ml-3 px-2 py-1 border border-gray-300 text-sm leading-5 cursor-pointer font-medium rounded shadow-sm px-4 py-2 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none">
                                <div v-if="sort && sort.type === 'desc'"><i class="fas fa-sort-amount-down"/></div>
                                <div v-else><i class="fas fa-sort-amount-up"/></div>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
            <!-- TABLE -->
            <div class="addresses-content flex flex-1 overflow-y-auto mb-6">
                <div class="align-middle inline-block min-w-full overflow-hidden px-6">

                    <!--                <div class="z-0 overflow-x-auto bg-white rounded-lg overflow-y-auto relative" :style="{height: tableHeight  + 'px'}">-->
                    <div class="z-0 overflow-x-auto bg-white rounded-lg overflow-y-auto relative border border-gray-200">
                        <table class="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                            <thead>
                            <tr class="text-left">
                                <th class="py-2 px-3 sticky top-0 border-b border-gray-200 bg-white">
                                    <label class="text-teal-500 inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer">
                                        <input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded" v-model="selectAll">
                                    </label>
                                <th class="bg-white sticky top-0 border-b border-gray-200 px-2 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">Edit</th>
                                <th v-for="col in columns" :key="col.id" :style="{minWidth: col.width + 'px' }"
                                    class="bg-white sticky top-0 border-b border-gray-200 px-2 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">{{ col.title }}
                                </th>
                            </tr>
                            </thead>

                            <tbody v-if="addresses.length>0">

                            <tr v-for="item in addresses"
                                :key="item.id"
                                @click.stop="handleClick(item, $event)"
                                :class="[
                                'text-gray-500 hover:bg-blue-500 hover:text-white',
                                 selected.includes(item.id) && 'bg-blue-50',
                                 currentlyActive === item.id && 'bg-blue-500 text-white'
                                 ]">
                                <td class="border-dashed border-t border-gray-200 px-3">
                                    <label class="inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer">
                                        <input type="checkbox"
                                               class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                                               :checked="selected.includes(item.id)"
                                               @click.stop="handleSelectCheckbox(item.id)">
                                    </label>
                                </td>
                                <td class="border-dashed border-t border-gray-200 px-3 cursor-pointer underline"
                                    @click="edit(item)"
                                    style="min-width: 60px">
                                    Edit
                                </td>

                                <td v-for="col in columns" :key="col.id" :style="{minWidth: col.width + 'px' }" class="border-dashed border-t border-gray-200 select-none">
                                    <component :is="col.component" :column="col.id" :item="item" @select-item="select"/>
                                </td>

                            </tr>
                            </tbody>


                            <tbody v-else>
                            <tr>
                                <td :colspan="Object.keys(columns).length + 2">
                                    <div v-if="!fetchingAddresses.loading && fetchingAddresses.error" class="flex rounded-md bg-red-50 p-4 items-center m-6">
                                        <i class="fal fa-exclamation-circle text-red-400 mr-3"/>
                                        <h3 class="text-sm font-medium text-red-800">{{ fetchingAddresses.error }}</h3>
                                    </div>
                                    <div v-else class="flex flex-row justify-center items-center p-2 font-bold text-2xl">No records found</div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>


            <div v-if="!fetchingAddresses.loading && $store.getters['master/totalCompanyAddresses'] > itemsPerPage"
                 class="absolute px-6 py-2 w-full h-14 static bottom-0 bg-gray-50 text-gray-800 left-0 right-0">
                <autralis-addresses-paginator-comp
                    :currentPage="page"
                    :recordsPerPage="itemsPerPage"
                    :totalCount="$store.getters['master/totalCompanyAddresses']"
                    @on-change-page="onPageChange"
                />
            </div>
        </div>
    </div>

</template>

<script>
import ErrorBox from "@/components/error-box";
import SuccessBox from "@/components/success-box";
import comboButton from '@/components/combo-button'
import BaseCol from '../../columns/base-column'
import BaseColCountry from '../../columns/base-column-country'
import BaseColCheck from '../../columns/base-column-check'
import Paginator from './components/Paginator'
import Modal from "@/components/modal";
import {modalInfo} from "@/common/modal";

import {filter, find, head, isBoolean, isEmpty, isInteger, orderBy, sortBy} from "lodash";
import {handleCompoundAddressChange, handleDefaultAddressChange, handleDeleteAddresses, handleFetchAddresses} from "./handlers";
import {DETAIL_PANEL_KEYS, HOME_LIST_ITEMS_PER_PAGE} from "../../../constants";
import LoadingTable from "@/components/loading-table";

const defaultColumns = {
    address: {width: 300, id: 'address', title: 'Address', component: 'autralis-base-col-comp', defaultSort: true},
    postalCode: {width: 120, id: 'postalCode', title: 'Postal code', component: 'autralis-base-col-comp', defaultSort: false},
    city: {width: 200, id: 'city', title: 'City', component: 'autralis-base-col-comp', defaultSort: false},
    countryCode: {width: 200, id: 'countryCode', title: 'Country', component: 'autralis-base-col-country-comp', defaultSort: false},
    default: {width: 100, id: 'default', title: 'Default', component: 'autralis-base-col-check-comp', defaultSort: false},
    compound: {width: 100, id: 'compound', title: 'Compound', component: 'autralis-base-col-check-comp', defaultSort: false},
};

export default {
    name: "Addresses",
    props: {
        companyId: {type: Number}
    },
    data() {
        return {
            columns: defaultColumns,
            views: [
                {
                    id: 'general',
                    title: 'General',
                    columns: defaultColumns
                }
            ],
            savedFlag: null,
            sortItem: null,
            selectAll: false,
            sortFlag: false,
            tableHeight: window.innerHeight - 206,
            selectedId: null,
            selected: [],
            fetchingAddresses: {
                loading: false,
                error: null
            },
            error: null,
            defaultAddress: {
                saving: false
            },
            compoundAddress: {
                saving: false
            },
            deleteAddress: {
                deleting: false
            },

            sort: null,
            //Pagination
            page: 1,
            itemsPerPage: HOME_LIST_ITEMS_PER_PAGE,
            totalCount: null,
            deleting: false,
            modal: modalInfo(),
            numClicks: 0,
            clickTimer: null,
            currentlyActive: null,
            menuOpen: false,
            headerTop: 0
        }
    },

    watch: {
        selectAll(val) {
            this.selected = val ? this.addresses.map(i => i.id) : []
        },
        sort() {
            this.updateCompanyAddresses(this.$store.getters['master/companyAddresses'])
        },
        '$store.state.master.detail': function () {
            const storeDetail = this.$store.state.master.detail;
            if (storeDetail) {
                if (storeDetail['key'] && storeDetail['key'] === DETAIL_PANEL_KEYS.EDIT_COMPANY_ADDRESS) {
                    this.currentlyActive = storeDetail['id']
                }
            } else {
                this.currentlyActive = null
            }
        },

        companyId(newCompanyId) {
            this.fetchAddresses(newCompanyId);
        }
    },

    computed: {
        addresses() {
            return this.sortedItems(this.$store.getters['master/companyAddresses']);
        },

        showSetDefaultButton() {
            if (this.selected.length === 1) {
                const selectedId = head(this.selected);
                const address = head(filter(this.$store.getters['master/companyAddresses'], address => address.id === selectedId))
                return address && !address.default;
            }

            return false;
        },

        showDeleteButton() {
            if (this.selected.length > 0) {
                const selectedAddresses = filter(this.$store.getters['master/companyAddresses'], address => this.selected.includes(address.id));
                const defaultInSelected = find(selectedAddresses, address => address.default);
                return defaultInSelected === undefined
            }

            return false;
        }
    },

    methods: {
        onResize() {
            if (window.innerWidth < 1024) {
                this.headerTop = 157;
            } else {
                this.headerTop = 0
            }
        },
        setInitSort() {
            const item = find(this.selectColumns(), col => col.defaultSort);
            if (item) this.selectSort(item);
        },

        singleRowClick(item) {
            this.handleSelectCheckbox(item.id)
        },
        doubleRowClick(item) {
            this.edit(item);
        },

        handleClick(item, e) {
            e.preventDefault()
            this.numClicks++;

            if (this.numClicks === 1) {
                this.clickTimer = setTimeout(() => {
                    this.numClicks = 0;
                    this.singleRowClick(item, e);
                }, 200);
            } else if (this.numClicks === 2) {
                clearTimeout(this.clickTimer);
                this.numClicks = 0;
                this.doubleRowClick(item, e);
            }
        },

        confirmDelete() {
            const msg = this.selected.length > 1 ? "Are you sure that you want to delete those " + this.selected.length + ' addresses?' :
                "Are you sure that you want to delete this address?";
            this.modal.view('Delete address', msg, 'Confirm', 'exclamation-triangle', this.handleDelete)
        },

        sortedItems(items) {
            let results = items;
            if (!this.sort) return results;

            if (isBoolean(items[this.sort.field]) || isInteger(items[this.sort.field])) {
                results = sortBy(items, [this.sort.field])
            } else {
                results = orderBy(items, [this.sort.field], [this.sort.type])
            }
            return results;
        },

        selectColumns() {
            return Object.keys(this.columns).map(c => {
                return {
                    item: c,
                    name: this.columns[c].title,
                    key: this.columns[c].id,
                    counter: null,
                    icon: null,
                    separator: false,
                    defaultSort: this.columns[c].defaultSort
                }
            });
        },

        handleSelectCheckbox(id) {
            let newSelected = null;
            if (find(this.selected, item => item === id)) {
                newSelected = filter(this.selected, item => item !== id);
            } else {
                newSelected = [...this.selected, id]
            }
            this.selected = newSelected
        },

        edit(item) {
            const detailPayload = {
                key: DETAIL_PANEL_KEYS.EDIT_COMPANY_ADDRESS,
                addressId: item.id,
                companyId: this.$props.companyId,
                page: this.page,
                itemsPerPage: this.itemsPerPage
            }
            this.$store.commit('master/setDetail', detailPayload);
            this.$store.commit('master/openDetail');
        },

        addNew() {
            const detailPayload = {
                key: DETAIL_PANEL_KEYS.EDIT_COMPANY_ADDRESS,
                companyId: this.$props.companyId,
                page: this.page,
                itemsPerPage: this.itemsPerPage
            }
            this.$store.commit('master/setDetail', detailPayload);
            this.$store.commit('master/openDetail');
        },

        select(item) {
            console.log("SELECT");
            console.log(item);
            // this.$emit('select-item', item)
            // this.$store.commit('seller/openItem', 0);
        },

        setSortType() {
            this.sort = {...this.sort, type: this.sort.type === 'asc' ? "desc" : "asc"}
        },

        selectSort(item) {
            this.sort = {field: item.key, title: item.name, type: 'asc'}
        },

        updateCompanyAddresses(items) {
            this.$store.commit('master/setCompanyAddresses', this.sortedItems(items))
        },

        setTotalCompanyAddresses(total) {
            this.$store.commit('master/setTotalCompanyAddresses', total)
        },

        onPageChange(page) {
            this.page = page;
            this.fetchAddresses(this.$props.companyId)
        },

        setOrder(field) {
            const existing_order = !isEmpty(this.sort);
            if (existing_order) {
                // Change type (ASC or DESC)
                this.sort = {
                    ...this.sort,
                    type: this.sort.type === 'asc' ? 'desc' : 'asc'
                }
            } else {
                // Add new order
                this.sort = {
                    field: field,
                    type: 'desc'
                }
            }
        },

        async handleDefault() {
            const addressId = head(this.selected);

            if (addressId) {
                if (this.selected.length === 1) {
                    try {
                        this.defaultAddress.saving = true;
                        this.savedFlag = false;
                        this.error = null;

                        const payload = {
                            companyId: this.$props.companyId,
                            addressId: addressId
                        };

                        await handleDefaultAddressChange(payload, (response) => {
                            this.updateCompanyAddresses(response.addresses)
                            this.selected = [];
                            this.selectAll = false;
                            this.savedFlag = true;
                        }, (error) => {
                            this.error = error;
                        })

                        this.defaultAddress.saving = false

                    } catch (err) {
                        this.error = 'Changing to default failed. Please try again.'
                        this.defaultAddress.saving = false
                    }
                }
            } else {
                this.error = 'No ID provided.'
            }
        },


        async handleCompound() {
            if (this.selected.length > 0) {

                try {
                    this.compoundAddress.saving = true;
                    this.savedFlag = false;
                    this.error = null;

                    const payload = {
                        data: this.addresses
                            .filter(address => this.selected.includes(address.id))
                            .map(address => ({id: address.id, compound: !address.compound})),
                        selected: this.selected
                    }

                    await handleCompoundAddressChange(payload, (response) => {
                        this.updateCompanyAddresses(response.addresses)
                        this.selected = [];
                        this.selectAll = false;
                        this.savedFlag = true;
                    }, (error) => {
                        this.error = error;
                    })


                    this.compoundAddress.saving = false

                } catch (err) {
                    this.error = 'Changing to compound failed. Please try again.'
                    this.compoundAddress.saving = false
                }
            } else {
                this.error = 'Please select at least one address.'
            }
        },

        async handleDelete() {
            try {
                this.deleteAddress.deleting = true;
                this.savedFlag = null;
                this.error = null

                const payload = {
                    data: this.selected,
                    page: this.page,
                    recordsPerPage: this.itemsPerPage,
                    companyId: this.$props.companyId
                };
                await handleDeleteAddresses(payload, (response) => {
                    this.updateCompanyAddresses(response.addresses)
                    this.selected = [];
                    this.selectAll = false;
                    this.savedFlag = true;
                }, (error) => {
                    this.error = error
                })

                this.deleteAddress.deleting = false;

            } catch (err) {
                this.error = 'The company address could not be deleted. Please try again.'
                this.deleteAddress.deleting = false;
            }
        },

        async fetchAddresses(companyId) {
            if (companyId) {
                try {
                    this.fetchingAddresses.loading = true;
                    this.fetchingAddresses.error = null;

                    const payload = {
                        companyId: companyId,
                        page: this.page,
                        itemsPerPage: this.itemsPerPage
                    }
                    await handleFetchAddresses(payload, (response) => {
                        this.page = response.page;
                        this.$store.commit('master/setTotalCompanyAddresses', response.totalCount);
                        this.updateCompanyAddresses(response.addresses);
                    }, () => {
                        this.fetchingAddresses.error = 'Loading company addresses failed. Please try again.'
                    })

                    this.fetchingAddresses.loading = false

                } catch (err) {
                    this.fetchingAddresses.error = 'Loading company addresses failed. Please try again.'
                    this.fetchingAddresses.loading = false
                }
            } else {
                this.fetchingAddresses.error = 'No company ID provided. '
            }
        },


    },

    components: {
        'autralis-combo-button-comp': comboButton,
        'autralis-base-col-comp': BaseCol,
        'autralis-base-col-country-comp': BaseColCountry,
        'autralis-base-col-check-comp': BaseColCheck,
        'autralis-success-comp': SuccessBox,
        'autralis-error-comp': ErrorBox,
        'autralis-addresses-paginator-comp': Paginator,
        'autralis-modal-comp': Modal,
        'autralis-loading-table-comp': LoadingTable,
    },

    created() {
        this.setInitSort();
        this.fetchAddresses(this.$props.companyId);
        window.addEventListener("resize", this.onResize);
        this.onResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize);
    },
}
</script>

<style scoped>
.dropdown-nav {
    top: 120%;
    min-width: 150px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.addresses-header, .addresses-content {
    background: rgba(250, 250, 250, 1);
}

@media (max-width: 767px) {
    .addresses-header, .addresses-content {
        background: white;
    }
}

</style>